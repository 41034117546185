import React from "react";
import Card from "~/components/styled/card";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { formatPrice } from "~/helpers/currency-formatter";
import { ArrowRightIcon } from "@heroicons/react/outline";
import PageHeading from "~/components/styled/page-heading";

const GuaranteedRides = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Guaranteed Bike Tours and Cycling Holidays"
				description="See the list of our guaranteed-to-depart bike tours in India and Asia."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/upcoming-rides",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// ]}
			/>
			<section className="mt-4 mb-10">
				<h1 className="font-bold">Guaranteed Departures</h1>
				<p className="font-light mt-2 text-primary text-xl max-w-3xl mb-14">
					Tours that have met our minimum people requirement
				</p>
				{/* <p className="mb-10 prose">
					Connect with the adventurers; who enjoy the camaraderie of exploring the
					world; over one of our guaranteed departures. Guaranteed-to-depart bicycle
					tours are those that have met our minimum people requirement.
				</p> */}
				{/*  You can also email us at{" "}
					<a
						
						className=" text-primary"
						href="mailto:contact@artofbicycletrips.com"
					>
						contact@artofbicycletrips.com
					</a>{" "}
					for any questions or booking a trip. */}

				{/* <div className="text-center">
					<PageHeading>Guaranteed-to-Depart Bike Tours</PageHeading>
				</div>

				<p className="leading-loose mb-10 font-light md:text-xl max-w-3xl m-auto">
					Connect with the adventurers; who enjoy the camaraderie of exploring the
					world; over one of our guaranteed departures. Guaranteed-to-depart bicycle
					tours are those that have met our minimum people requirement. Most of our
					cycling tours depart with a minimum of 2 people traveling.
				</p>
				
				<h3 className="text-center mb-4">
					Get Notified of Our Guaranteed Departures
				</h3>
				<a href="https://www.instagram.com/artofbicycleglobal/" target="_blank">
					<p className="leading-loose hover:text-primary md:text-xl max-w-2xl m-auto">
						‣ Follow us on Instagram
					</p>
				</a> */}

				{/* <a href="https://chat.whatsapp.com/BmatbfMpjQ42m4JkaLgJCP" target="_blank">
					<p className="leading-loose hover:text-primary md:text-xl max-w-2xl m-auto">
						‣ Join our WhatsApp group Or
					</p>
				</a>
				<a href="http://eepurl.com/hnf28r" target="_blank">
					<p className="leading-loose hover:text-primary md:text-xl max-w-2xl m-auto">
						‣ Subscribe to our newsletter
					</p>
				</a> */}
				{/* <h2 className="mt-20 mb-6">November 2022</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/highlights-of-cambodia-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Highlights of Cambodia
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1495 && formatPrice(1495)} | 07 Nov to 13 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">January 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
				
					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1695 && formatPrice(1695)} | 8 - 14 January
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>

					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1695 && formatPrice(1695)} | 22 - 28 January
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/phnom-penh-to-koh-rong-island-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										South to the Sea
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {900 && formatPrice(900)} | 26 - 29 January
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">4 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">February 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/cycling-cambodia/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-6.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Charismatic Cambodia Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2395 && formatPrice(2395)} | 03 Feb to 12 Feb
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1695 && formatPrice(1695)} | 04 - 10 Feb
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 05 - 21 February
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/grand-cambodia-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Grand Cambodia
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2150 && formatPrice(2150)} | 05 - 13 February
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>

					
					<Card>
						<Link to="/tours/cycling-laos/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Dramatic Northern Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 19 Feb - 04 Mar
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycle-vietnam-to-cambodia/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										South Vietnam and Grand Cambodia
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3195 && formatPrice(3195)} | 12 - 25 February
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">March 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/saigon-to-angkor-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-21.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Saigon to Angkor
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | 05 - 13 March
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 05 - 21 March
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/portrayal-of-vietnam-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-13.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Portrayal of Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | 11 - 20 March
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/vietnam-cambodia-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3195 && formatPrice(3195)} | 12 - 20 March
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-coastal-vietnam-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 12 - 20 March
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-laos-luang-prabang-to-vientiane-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-2.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Luang Prabang to Vientiane Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1795 && formatPrice(1795)} | 19 Mar to 26 Mar
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-mekong/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-22.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Mekong Delta Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {850 && formatPrice(850)} | 20 - 22 March
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">3 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>

					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1695 && formatPrice(1695)} | 19 - 25 March
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">April 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/saigon-to-angkor-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-21.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Saigon to Angkor
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | 09 Apr to 17 Apr
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="https://docs.google.com/document/d/16iw732APQpYel_FkLGP8ouR98oy_jOSx_GnsCJDtqM0/edit?usp=sharing">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-14.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Essential Vietnam
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 08 Apr to 18 Apr
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">11 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-coastal-vietnam-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 20 Apr to 28 Apr
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">May 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/coastal-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 14 - 22 May
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-21.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Saigon to Angkor
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | 21 May to 29 May
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/phnom-penh-to-koh-rong-island-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										South to the Sea
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {900 && formatPrice(900)} | 30 May to 02 Jun
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">4 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}
				{/* <h2 className="mt-20 mb-6">June 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/highlights-of-cambodia-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Highlights of Cambodia
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1495 && formatPrice(1495)} | 05 Jun to 11 Jun
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-17.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Hanoi Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 11 Jun to 24 Jun
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/northern-vietnam-bike-hike-kayak/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam: bike, hike, kayak
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2995 && formatPrice(2995)} | 11 Jun to 24 Jun
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}
				{/* <h2 className="mt-20 mb-6">August 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/cycling-vietnam/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-17.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize ary">
										Saigon to Hanoi Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 20 Aug to 02 Sep
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/japan-hokkaido-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/east-hokkaido-cycling-east-coast.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Eastern Hokkaido Lakes & Coast Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 29 Aug to 05 Sep
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>

				<h2 className="mt-20 mb-6">September 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 17 Sep to 25 Sep
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2995 && formatPrice(2995)} | 17 Sep to 24 Sep
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-bhutan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/bhutan-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Bhutan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Bhutan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 24 Sep to 01 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">October 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/cycling-jordan/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/jordan-biking.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Jordan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Jordan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2795 && formatPrice(2795)} | Oct 07 - Oct 14
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/glimpse-sri-lanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 08 Oct to 15 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-bhutan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/bhutan-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Bhutan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Bhutan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 22 Oct to 29 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}
				{/* <h2 className="mt-20 mb-6">November 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 12 Nov to 25 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-laos/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Dramatic Northern Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 15 Nov to 28 Nov, 2023
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/portrayal-of-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-13.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Portrayal of Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4395 && formatPrice(4395)} | 17 - 26 November
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3395 && formatPrice(3395)} | 19 Nov to 27 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-india/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-23.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Exotic India Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3395 && formatPrice(3395)} | 18 Nov to 01 Dec
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/coastal-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3395 && formatPrice(3395)} | 19 Nov to 27 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/laos-bike-hike-kayak/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Laos - bike, hike, kayak
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">multisport</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2595 && formatPrice(2595)} | 26 Nov to 07 Dec, 2023
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">12 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">December 2023</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/mekong-bicycle-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-22.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Rambling Mekong Delta Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {800 && formatPrice(800)} | 04 Dec to 07 Dec
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">4 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4095 && formatPrice(4095)} | 17 Dec to 02 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/southern-thailand-bangkok-to-phuket-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Southern Thailand: Bangkok to Phuket Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 17 Dec to 30 Dec
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-laos/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Dramatic Northern Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 17 Dec to 30 Dec, 2023
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					
					<Card>
						<Link to="/tours/grand-cambodia-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Grand Cambodia Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3650 && formatPrice(3650)} | 17 Dec to 25 Dec
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/bangalore-goa-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/south-india-bike-tour-14.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Bangalore to Goa Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2995 && formatPrice(2995)} | 21 Dec to 03 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 29 Dec to 11 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 31 Dec to 07 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">January 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/highlights-of-cambodia-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Highlights of Cambodia
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1595 && formatPrice(1595)} | 01 Jan to 07 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/colorful-rajasthan-luxury-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-4.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Colorful Rajasthan Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 07 Jan to 14 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/southern-india-sojourn-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-27.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Southern India Sojourn Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3795 && formatPrice(3795)} | 11 Jan to 27 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4895 && formatPrice(4895)} | 14 Jan to 25 Jan
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">12 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2995 && formatPrice(2995)} | 14 Jan to 21 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/coastal-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 14 Jan to 22 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-laos/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Dramatic Northern Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 14 Jan to 27 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-21.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Saigon to Angkor
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 21 Jan to 29 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/krabi-koh-samui-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Krabi to Koh Samui Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 21 Jan to 28 Jan, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/krabi-koh-samui-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Krabi to Koh Samui Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 28 Jan to 04 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">February 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/krabi-koh-samui-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Krabi to Koh Samui Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 04 Feb to 11 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kerala-backwaters-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-27.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kerala Backwaters Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {900 && formatPrice(900)} | 06 Feb to 09 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">4 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-laos/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Dramatic Northern Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 11 Feb to 24 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/central-goa-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/goa-bike-tour-7.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Enchanting Goa Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1195 && formatPrice(1195)} | 11 Feb to 15 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">5 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/princely-rajasthan-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-24.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Princely Rajasthan Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {5795 && formatPrice(5795)} | 04 Feb to 16 Feb
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/bangkok-chiangrai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-9.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Bangkok to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3395 && formatPrice(3395)} | 04 Feb to 17 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2995 && formatPrice(2995)} | 11 Feb to 18 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3395 && formatPrice(3395)} | 16 Feb to 24 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-laos/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Dramatic Northern Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 18 Feb to 02 Mar, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/hue-hoi-an-family-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Hue to Hoi An Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1295 && formatPrice(1295)} | 18 Feb to 23 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">6 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/krabi-koh-samui-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Krabi to Koh Samui Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 18 Feb to 25 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					
					<Card>
						<Link to="/tours/glimpse-sri-lanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 18 Feb to 26 Feb
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-kerala/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Cycling Kerala Escapade
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 25 Feb to 05 Mar, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mekong-delta-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-22.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Mekong Delta Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {850 && formatPrice(850)} | 26 Feb to 28 Feb, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">3 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">March 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3395 && formatPrice(3395)} | 04 Mar to 12 Mar, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2995 && formatPrice(2995)} | 10 Mar to 17 Mar, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/northern-vietnam-bike-hike-kayak/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam: bike, hike, kayak
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3195 && formatPrice(3195)} | 17 Mar to 30 Mar
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/east-coast-taiwan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/taiwan-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Enchanting East Coast of Taiwan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Taiwan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 17 Mar to 24 Mar
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/coastal-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 31 Mar to 08 Apr
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-21.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Saigon to Angkor
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 31 Mar to 08 Apr, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">April 2024</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/vivid-srilanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Vivid Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3095 && formatPrice(3095)} | 07 Apr to 19 Apr
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/albania-unesco-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/albania-tour.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Albania Unesco Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Albania</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | 15 Apr to 24 Apr, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">May 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3395 && formatPrice(3395)} | 05 May to 13 May, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/albania-unesco-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/albania-tour.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Albania Unesco Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Albania</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | 23 May to 01 Jun, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">June 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/classic-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4895 && formatPrice(4895)} | 29 Jun to 10 Jul
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">12 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}
				{/* <h2 className="mt-20 mb-6">July 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/coastal-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 07 Jul to 15 Jul
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/northern-vietnam-bike-hike-kayak/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam: bike, hike, kayak
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3195 && formatPrice(3195)} | 21 Jul to 03 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">August 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/japan-hokkaido-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/east-hokkaido-cycling-east-coast.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Eastern Hokkaido Lakes & Coast Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 04 Aug to 11 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/ho-chi-minh-trail-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ho Chi Minh Trail by Gravel Bike
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 04 Aug to 11 Aug, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/highlights-of-cambodia-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Highlights of Cambodia
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1515 && formatPrice(1515)} | 12 Aug to 18 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {5295 && formatPrice(5295)} | 18 Aug to 30 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">September 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/kyoto-sea-of-japan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/japan-kyoto-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Kyoto to Sea of Japan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 03 Sep to 10 Sep, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/noto-peninsula-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/noto-day4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Noto Peninsula Plus! the Alps Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 18 Sep to 25 Sep, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-laos-luang-prabang-to-vientiane-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-2.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Luang Prabang to Vientiane Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 22 Sep to 29 Sep
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">October 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/essense-of-shikoku-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/shikoku-9.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Essence of Shikoku Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 10 Oct to 17 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/princely-rajasthan-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-24.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Princely Rajasthan Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {5795 && formatPrice(5795)} | 13 Oct to 25 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/transylvania-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/romania-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Enigmatic Transylvania Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Romania</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2595 && formatPrice(2595)} | 14 Oct to 21 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/albania-unesco-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/albania-tour.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Albania Unesco Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Albania</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | 16 Oct to 25 Oct, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/southern-india-sojourn-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-27.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Southern India Sojourn Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4049 && formatPrice(4049)} | 19 Oct to 04 Nov, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/exquisite-thailand-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Exquisite Thailand Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3095 && formatPrice(3095)} | 20 Oct to 28 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-kerala-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Kerala Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 20 Oct to 28 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/east-coast-taiwan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/taiwan-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Enchanting East Coast of Taiwan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Taiwan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 26 Oct to 03 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kyoto-sea-of-japan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/japan-kyoto-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kyoto to Sea of Japan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 27 Oct to 03 Nov, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/vietnam-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-2.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam & Laos: Hanoi to Luang Prabang Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2995 && formatPrice(2995)} | 28 Oct to 10 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">November 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 04 Nov to 20 Nov, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 06 Nov to 19 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailnd-multisport-bike-hike/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-9.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Off-Beat Northern Thailand Bike, Hike & Raft
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2695 && formatPrice(2695)} | 09 Nov to 19 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">11 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | 10 Nov to 26 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 10 Nov to 18 Nov, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/coast-to-coast-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-27.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coast to Coast Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4620 && formatPrice(4620)} | 17 Nov to 30 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/southern-thailand-bangkok-to-phuket-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Southern Thailand: Bangkok to Phuket Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3895 && formatPrice(3895)} | 17 Nov to 30 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/dramatic-northern-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Dramatic Northern Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3450 && formatPrice(3450)} | 17 Nov to 30 Nov, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/exquisite-thailand-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Exquisite Thailand Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3095 && formatPrice(3095)} | 17 Nov to 25 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-hanoi-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-17.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Saigon to Hanoi Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3450 && formatPrice(3450)} | 17 Nov to 30 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3395 && formatPrice(3395)} | 23 Nov to 01 Dec, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/glimpse-sri-lanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 24 Nov to 02 Dec
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/vietnam-and-cambodia-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-21.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Vietnam and Cambodia: Saigon to Angkor Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 24 Nov to 02 Dec, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}

				{/* <h2 className="mt-20 mb-6">December 2024</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/vietnam-and-cambodia-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-21.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Vietnam and Cambodia: Saigon to Angkor Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 15 Dec to 23 Dec
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 15 Dec to 28 Dec, 2024
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mae-hong-son-highlands-loop-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-9.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Mae Hong Son Highlands Loop
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2695 && formatPrice(2695)} | 15 Dec to 25 Dec
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">11 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 26 Dec to Wed, 08 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {5295 && formatPrice(5295)} | 28 Dec to Thu, 09 Jan
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/essential-philippines-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/philippines_bohol.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Essential Philippines Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Philippines
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2395 && formatPrice(2395)} | 29 Dec to Mon, 06 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/rambling-mekong-delta-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-22.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Rambling Mekong Delta Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1000 && formatPrice(1000)} | 31 Dec to Sat, 04 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">5 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}
				<h2 className="mt-20 mb-6">January 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/philippines-island-odyssey-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/philippines_bohol.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Philippines Island Odyssey Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Philippines
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 01 Jan, 2025 to Tue, 14 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-rajasthan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-4.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Rajasthan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 04 Jan to 11 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/ho-chi-minh-trail-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ho Chi Minh Trail by Gravel Bike
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 09 Jan to Thu, 16 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | 10 Jan to 26 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-laos-luang-prabang-to-vientiane-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-2.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Laos - Luang Prabang to Vientiane Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 12 Jan, 2025 to Sun, 19 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailnd-multisport-bike-hike/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-9.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Off-Beat Northern Thailand Bike, Hike & Raft
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2895 && formatPrice(2895)} | Sun, 12 Jan, 2025 to Wed, 22 Jan,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">11 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kerala-escapade-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kerala Escapade
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 12 Jan to Tue, 21 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/exquisite-thailand-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Exquisite Thailand Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3195 && formatPrice(3195)} | 12 Jan to 20 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/central-goa-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/goa-bike-tour-7.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Enchanting Goa Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1195 && formatPrice(1195)} | 12 Jan, 2025 to 16 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">5 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/portrayal-of-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-13.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Portrayal of Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 12 Jan, 2025 to Tue, 21 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 12 Jan to 20 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 12 Jan to 20 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/southern-thailand-bangkok-to-phuket-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Southern Thailand: Bangkok to Phuket Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3895 && formatPrice(3895)} | 19 Jan to Sat, 01 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/northern-vietnam-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-17.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 19 Jan to 27 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/vivid-srilanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Vivid Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 19 Jan to 01 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | Sun, 26 Jan, 2025 to Sun, 02 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>

				<h2 className="mt-20 mb-6">February 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | Sun, 02 Feb, 2025 to Sat, 15 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | 02 Feb to 09 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/portrayal-of-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-13.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Portrayal of Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | Sat, 08 Feb, 2025 to Mon, 17 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/philippines-island-odyssey-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/philippines_bohol.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Philippines Island Odyssey Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Philippines
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 08 Feb to 21 Feb
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-laos-luang-prabang-to-vientiane-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-2.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Laos - Luang Prabang to Vientiane Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | Thu, 13 Feb, 2025 to Thu, 20 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | Sun, 16 Feb, 2025 to Mon, 24 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/glimpse-sri-lanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 16 Feb to Mon, 24 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/exotic-india-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-23.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Exotic India Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 15 Feb, 2025 to 28 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 16 Feb, 2025 to 24 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-rajasthan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-4.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Rajasthan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 16 Feb to 23 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kerala-in-style-bike-hike-kayak/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kerala in Style: bike, hike, kayak
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2295 && formatPrice(2295)} | 09 Feb to 19 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">11 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/northern-vietnam-bike-hike-kayak/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam: bike, hike, kayak
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3195 && formatPrice(3195)} | 09 Feb to 22 Feb
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 23 Feb - 08 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">March 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | Sun, 02 Mar, 2025 to Mon, 10 Mar,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | 02 Mar to 18 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {5295 && formatPrice(5295)} | 02 Mar to 14 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/coastal-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 09 Mar to Mon, 17 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 09 Mar, 2025 to Mon, 17 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/highlights-of-cambodia-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Highlights of Cambodia
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1515 && formatPrice(1515)} | 10 Mar, 2025 to Sun, 16 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kyoto-sea-of-japan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/japan-kyoto-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kyoto to Sea of Japan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4495 && formatPrice(4495)} | 26 Mar to 02 Apr, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/hue-hoi-an-family-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Hue to Hoi An Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1295 && formatPrice(1295)} | 30 Mar to Fri, 04 Apr, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">6 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">April 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/philippines-island-odyssey-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/philippines_bohol.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Philippines Island Odyssey Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Philippines
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 02 Apr, 2025 to Tue, 15 Apr, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/vivid-srilanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Vivid Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 06 - 19 Apr, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">May 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | Thu, 01 May, 2025 to Fri, 09 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/self-guided-douro-valley-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/douro-portugal.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Self-guided Porto & Douro Valley Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | Sat, 10 May, 2025 to Sat, 17 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | Mon, 12 May, 2025 to Wed, 28 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/essense-of-shikoku-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/shikoku-9.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Essence of Shikoku Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | Tue, 13 May, 2025 to Tue, 20 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>

					<Card>
						<Link to="/tours/montenegro-albania-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/Albania_Herceg_Novi.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Montenegro and Albania Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Montenegro, Albania
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2395 && formatPrice(2395)} | Sun, 18 May, 2025 to Sun, 25 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">July 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/fjords-of-norway-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/norwaylake.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Fjords of Norway Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Norway</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4695 && formatPrice(4695)} | 10 Jul to 17 Jul, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mongolia-steppe-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/Mangolia_Orkhon.webp"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Mongolia Steppe Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Mongolia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 13 Jul to 26 Jul
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>

				<h2 className="mt-20 mb-6">August 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					{/* <Card>
						<Link to="/tours/japan-hokkaido-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/east-hokkaido-cycling-east-coast.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Eastern Hokkaido Lakes & Coast Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4795 && formatPrice(4795)} | 10 Aug to 17 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card> */}
					<Card>
						<Link to="/tours/fjords-of-norway-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/norwaylake.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Fjords of Norway Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Norway</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4695 && formatPrice(4695)} | Mon, 18 Aug, 2025 to Mon, 25 Aug,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/self-guided-porto-santiago-compostela-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/camino.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Self-guided Porto to Santiago de Compostela Coastal Path Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 24 Aug to 31 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">
											casual plus inns
										</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">September 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/alentejo-castles-beaches-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/portugal-evora.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Alentejo to Algarve Castles and Beaches Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 06 Sep to 13 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kyoto-sea-of-japan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/japan-kyoto-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kyoto to Sea of Japan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4595 && formatPrice(4595)} | 11 Sep to 18 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {5295 && formatPrice(5295)} | 13 - 25 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 14 Sep to 22 Sep
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/albania-unesco-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/albania-tour.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Albania Unesco Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Albania</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2395 && formatPrice(2395)} | Sat, 13 Sep, 2025 to Mon, 22 Sep,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/alentejo-castles-beaches-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/portugal-evora.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Alentejo to Algarve Castles and Beaches Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 20 Sep, 2025 to 27 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mystical-bhutan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/bhutan-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Bhutanese Dragon Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Bhutan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {6195 && formatPrice(6195)} | Sun, 28 Sep, 2025 to Sat, 11 Oct,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">October 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/alentejo-castles-beaches-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/portugal-evora.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Alentejo to Algarve Castles and Beaches Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 11 - 18 Oct, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mystical-bhutan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/bhutan-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Bhutanese Dragon Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Bhutan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {6195 && formatPrice(6195)} | 12 Oct to 25 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">November 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 16 - 24 Nov, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
			</section>
		</Layout>
	);
};

export default GuaranteedRides;
